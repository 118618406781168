import styled from "styled-components"

export const CompanyPadding = styled.div`
  padding: 100px 0px;
  margin: 0 16px;
  height: 100%;

  @media (max-width: 968px) {
    padding: 80px 0px;
  }
`

export const CompanyFlexBox = styled.div`
  display: flex;
  justify-content: space-between;

  gap: 30px;

  @media (max-width: 968px) {
    flex-direction: column;
    align-items: center;
    padding-left: 18%;
  }

  @media (max-width: 768px) {
    padding-left: 2%;
  }

  @media (max-width: 500px) {
    flex-direction: column;
    align-items: center;
    padding-left: 0;
  }
`

export const ParagraphHolder = styled.div`
  @media (max-width: 968px) {
    max-width: 520px;
  }
`

export const Button = styled.button`
  margin: 0 auto;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 15px;
  width: 100%;
  max-width: max-content;
  height: 30px;
  padding: 32px;
  color: #ffff;
  font-weight: 900;
  font-size: 22px;

  border-radius: 100px;
  filter: drop-shadow(0px 13px 4px rgba(12, 150, 255, 0.17));
  background-color: #13b0fc;

  :hover {
    background-color: #40bfff;
  }
`

export const SubTitle = styled.h2`
  margin: ${(props) => props.margin || "0"};
  max-width: ${(props) => props.maxw};
  font-size: 32px;
  color: #000000;
  font-weight: 700;
  color: ${(props) => props.color || "#00000"};
  font-family: "DM Sans";
  line-height: ${(props) => props.lineHeight};

  @media (max-width: 768px) {
    font-size: 28px;
  }
`

export const Strong = styled.strong`
  font-size: 26px;

  @media (max-width: 768px) {
    font-size: 24px;
  }
`

export const ItemHolder = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 968px) {
    max-width: 480px;
  }
`
