import styled from "styled-components"
import BorderLine from "../../../assets/img/TimelineBorder.svg"

export const TimeLineHolder = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`

export const ContentHolder = styled.div`
  display: flex;
  flex-direction: column;
`
export const StoryHolder = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 auto;
`

export const SubTitle = styled.div`
  margin: ${(props) => props.margin || "0"};
  max-width: ${(props) => props.maxw};
  font-size: 28px;
  color: #000000;
  font-weight: 700;
  color: ${(props) => props.color || "#00000"};
  font-family: "DM Sans";
  line-height: ${(props) => props.lineHeight};
  margin-top: 3px;
  @media (max-width: 768px) {
    font-size: 24px;
  }

  @media (max-width: 765px) {
    padding-top: 0;
  }
`

export const TimelineYear = styled.div`
  margin: 0;
  padding-right: 5px;
  font-size: 48px;
  font-weight: 700;
  color: #13b0fc;
  font-family: "DM Sans";
  min-width: 123px;
  margin-top: -8px;

  @media (max-width: 765px) {
    display: none;
  }
`
export const Year = styled.div`
  display: none;
  padding-top: 10px;
  margin: ${(props) => props.margin || "0"};
  max-width: ${(props) => props.maxw};
  font-size: 28px;
  color: #000000;
  font-weight: 700;
  color: #13b0fc;
  font-family: "DM Sans";
  line-height: ${(props) => props.lineHeight};

  @media (max-width: 765px) {
    display: block;
    font-size: 24px;
    padding-top: 7px;
  }
`

export const Paragraph = styled.p`
  max-width: ${(props) => props.maxw};
  margin: ${(props) => props.margin || "0"};
  font-size: ${(props) => props.fontSize || "18px"};
  color: ${(props) => props.color || "#00000"};
  font-weight: 400;
  font-family: "DM Sans";
  text-align: ${(props) => props.align};
  padding: 20px 0px;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`
export const LineHolder = styled.div`
  margin-right: 4%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 40px;
`
export const Line = styled.div`
  width: 3px;
  margin-left: 1px;
  height: 100%;
  background-color: #13b0fc;
`
export const LineIcon = styled.img`
  min-height: 10px;
  min-width: 38px;
`
export const PDFLink = styled.a`
  color: #13aefc;
  font-weight: 600;
  text-decoration: underline;

  :hover {
    color: #73c6f0;
  }
`
