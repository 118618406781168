import styled from "styled-components"

export const QuoteHolder = styled.div`
  margin: 0 auto;
  margin-top: 40px;
  display: flex;
  gap: 36px;
  padding: 32px 48px;
  border-radius: 32px;
  background-image: linear-gradient(45deg, #edf9ff 0%, #fff8f8 100%);
  max-width: max-content;

  @media (max-width: 768px) {
    margin-top: 20px;
    flex-direction: column;
    gap: 10px;
  }
`
export const Quote = styled.p`
  max-width: 830px;
  font-size: 30px;
  letter-spacing: 0px;
  line-height: 30px;
  color: #000000;
  font-weight: 700;
  font-family: "DM Sans";

  @media (max-width: 768px) {
    font-size: 24px;
  }
`
export const IconHolde = styled.div`
  margin: 0 auto;
  margin-top: 4px;

  @media (max-width: 768px) {
    margin: 0 0;
  }
`
export const CeoHolder = styled.div`
  margin-top: 30px;
  display: flex;
  gap: 10px;
`
export const Name = styled.p`
  margin-bottom: 10px;
  font-size: 20px;
  line-height: 18px;
  color: #000000;
  font-weight: 700;
  font-family: "DM Sans";
`
export const PositionTitle = styled.p`
  margin-bottom: 10px;
  font-size: 20px;
  line-height: 20px;
  color: #000000;
  font-weight: 400;
  font-family: "DM Sans";
`
export const ImageIcon = styled.img`
  margin-right: 8px;
  height: 26px;
  width: 26px;

  cursor: pointer;
`
export const CeoImageHolder = styled.div`
  border-radius: 100%;
`
export const CeoImage = styled.img`
  border-radius: 100%;
  height: 80px;
  width: 80px;
`
export const AboutHeroTitleHolder = styled.div`
  margin: 160px 0px 80px 0px;

  @media (max-width: 968px) {
    margin: 130px 0px 70px 0px;
  }

  @media (max-width: 768px) {
    margin: 110px 0px 70px 0px;
  }
`
