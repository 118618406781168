import React from "react"
import {
  BlueText,
  Container,
  Paragraph,
  SubTitle,
  Title,
  Wrapper,
} from "../shared.styles"
import {
  AspectHolder,
  AspectImage,
  AspectItem,
  BorderTop,
  ContentAspect,
  NumberHolder,
} from "./aspect.styled"
import AspecIcon1 from "../../../assets/img/aspect1.svg"
import AspecIcon2 from "../../../assets/img/ic-scales.svg"

const Aaspects = () => {
  return (
    <BorderTop>
      <Wrapper>
        <Container>
          <Title>What Makes LicenseSpring Different</Title>
          <Paragraph align="center" margin="20px 0px 0px 0px">
            There are two aspects that sets us apart:
          </Paragraph>
          <AspectHolder>
            <div>
              <AspectItem>
                <AspectImage src={AspecIcon1} />
                <NumberHolder>1.</NumberHolder>
              </AspectItem>
              <ContentAspect>
                <SubTitle lineHeight="40px">
                  Our attention to acting on product{" "}
                  <BlueText>quality</BlueText>:
                </SubTitle>
                <Paragraph>
                  We strive to make LicenseSpring easy to configure and use,
                  robust, flexible, intended to be used by different roles
                  within a company and their customers.
                </Paragraph>
              </ContentAspect>
            </div>
            <div>
              <AspectItem>
                <AspectImage src={AspecIcon2} />
                <NumberHolder>2.</NumberHolder>
              </AspectItem>
              <ContentAspect>
                <SubTitle lineHeight="40px">
                  <BlueText>Priced by value provided</BlueText>, not per
                  licensed revenue:
                </SubTitle>
                <Paragraph maxw="1300px">
                  We want to provide organizations of most shapes and sizes a
                  means to deliver and to license their software and hardware
                  based products. We are not owned by private equity, and do not
                  charge based on licensed revenue. Most of our customers find
                  our cost will be a fraction of other vendors, with a superior
                  product.
                </Paragraph>
              </ContentAspect>
            </div>
          </AspectHolder>
        </Container>
      </Wrapper>
    </BorderTop>
  )
}

export default Aaspects
