import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import { Hero } from "../components/about-us/hero/hero"
import { Story } from "../components/about-us/our-story/story"
import Aaspects from "../components/about-us/aspects/aspects"
import CompanyDetails from "../components/about-us/company-details/company-details"

const schemaMarkup = `<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "Article",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://licensespring.com/about-us/"
  },
  "headline": "About Us: LicenseSpring",
  "description": "Discover LicenseSpring's software licensing journey, where innovation and unwavering values converge to drive our mission of providing cutting-edge solutions.",
  "image": [
  ],  
  "author": {
  "@context": "https://schema.org/",
  "@type": "Person",
  "name": "Edmon Moren",
  "url": "https://licensespring.com/blog/Edmon-Moren/",
  "image": "https://images.prismic.io/licensespring/8ff52074-a9cf-4a48-bdee-7e6414ec914d_me.jpeg?ixlib=gatsbyFP&auto=compress%2Cformat&fit=max&w=322&h=322",
  "sameAs": [
    "https://www.linkedin.com/in/edmonmoren/",
    "https://twitter.com/LicenseSpring",
    "https://www.youtube.com/@licensespring"
  ],
  "jobTitle": "Co-Founder",
  "worksFor": {
    "@type": "Organization",
    "name": "LicenseSpring Software"
  }  
}},  
  "datePublished": "2023-08-22",
  "dateModified": "2023-08-22"
}
</script>

`

const AboutUs = () => {
  return (
    <Layout>
      <SEO
        title="LicenseSpring: About Us"
        description="Discover LicenseSpring's software licensing journey, where innovation and unwavering values converge to drive our mission of providing cutting-edge solutions.
      "
        schemaMarkup={schemaMarkup}
      />
      <Hero />
      <Story />
      <Aaspects />
      <CompanyDetails />
    </Layout>
  )
}

export default AboutUs
