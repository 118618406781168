import React from "react"
import {
  BlueText,
  Container,
  Paragraph,
  Title,
  Wrapper,
} from "../shared.styles"
import {
  SubTitle,
  Button,
  CompanyFlexBox,
  CompanyPadding,
  ParagraphHolder,
  ItemHolder,
} from "./company-details.styled"
import LinkedIn from "../../../assets/img/linkedin2.svg"

const CompanyDetails = () => {
  return (
    <Wrapper backgroundColor="#f0f9fe" margin="0">
      <CompanyPadding>
        <Container>
          <Title>
            <BlueText>LicenseSpring</BlueText>® Is Brought to You By:
          </Title>
          <Paragraph align="center" margin="40px auto 0px auto" maxw="640px">
            There are two primary companies involved in the development and the
            commercialization of the LicenseSpring service:
          </Paragraph>
          <CompanyFlexBox>
            <ItemHolder>
              <SubTitle
                margin="50px 0px 0px 0px"
                maxw="500px"
                lineHeight="36px"
              >
                Cense Data Inc DBA LicenseSpring
              </SubTitle>
              <Paragraph margin="15px 0px 0px 0px">
                Markets, sells, provides support for LicenseSpring..
              </Paragraph>
              <div>
                <Paragraph margin="15px 0px 10px 0px">
                  <strong>Vancouver Office:</strong>
                </Paragraph>
                <Paragraph>
                  Bentall 2, floor 15, 555 Burrard St, Vancouver, BC V7X 1M8
                </Paragraph>
                <Paragraph>D-U-N-S number: 243161611</Paragraph>
                <Paragraph>Managing Director: Edmon Moren</Paragraph>
              </div>
            </ItemHolder>
            <ItemHolder>
              <SubTitle margin="50px 0px 0px 0px" lineHeight="36px">
                Kraken Systems ltd
              </SubTitle>
              <Paragraph margin="0px 0px 0px 0px" maxw="475px">
                <ParagraphHolder>
                  is the primary developer and maintainer of the LicenseSpring
                  product and Infrastructure.
                </ParagraphHolder>
              </Paragraph>
              <Paragraph>
                It has received{" "}
                <BlueText>
                  <a
                    href="https://krakensystems.co/assets/pdf/Kraken_ISO_27001.pdf"
                    rel="noopener"
                    target="_blank"
                  >
                    ISO 27001
                  </a>
                </BlueText>{" "}
                and{" "}
                <BlueText>
                  <a
                    href="https://krakensystems.co/assets/pdf/Kraken_ISO_9001.pdf"
                    rel="noopener"
                    target="_blank"
                  >
                    ISO 9001
                  </a>
                </BlueText>{" "}
                certification
              </Paragraph>
              <div>
                <Paragraph margin="15px 0px 10px 0px">
                  <strong>Zagreb Office:</strong>
                </Paragraph>
                <Paragraph>
                  Zagrebacka avenija 104d 10000 Zagreb Croatia
                </Paragraph>
                <Paragraph>Managing Director: Dejan Strbad</Paragraph>
                <Paragraph>Managing Director: Marin Bek</Paragraph>
              </div>
            </ItemHolder>
          </CompanyFlexBox>
          <Paragraph align="center" margin="70px 0px 0px 0px">
            Invite to follow us on LinkedIn for job opportunities
          </Paragraph>
          <a
            href="https://ca.linkedin.com/company/licensespring"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button>
              <img src={LinkedIn} />
              View job openings
            </Button>
          </a>
        </Container>
      </CompanyPadding>
    </Wrapper>
  )
}

export default CompanyDetails
