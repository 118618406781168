import React from "react"
import { Container, Paragraph, Title, Wrapper } from "../shared.styles"
import {
  AboutHeroTitleHolder,
  CeoHolder,
  CeoImage,
  IconHolde,
  ImageIcon,
  Name,
  PositionTitle,
  Quote,
  QuoteHolder,
} from "./hero.styled"
import QuoteIcon from "../../../assets/img/ic-quote.svg"
import LinkedIn from "../../../assets/img/ic-linkedin.svg"
import Email from "../../../assets/img/ic-email.svg"
import CeoImageIcon from "../../../assets/img/CeoImage.png"

export const Hero = () => {
  return (
    <Wrapper>
      <Container>
        <AboutHeroTitleHolder>
          <Title>About Us: LicenseSpring</Title>
        </AboutHeroTitleHolder>
        <QuoteHolder>
          <IconHolde>
            <img src={QuoteIcon} />
          </IconHolde>
          <div>
            <Quote>
              Our goal is to be the best Software Licensing Service in the
              world. To us, this means offering a secure, reliable service, easy
              to use and sufficiently flexible to integrate within our
              customers’ existing systems.
            </Quote>
            <CeoHolder>
              <CeoImage src={CeoImageIcon} />
              <div>
                <Name>Edmon Moren</Name>
                <PositionTitle>
                  CEO, Cense Data Inc. DBA LicenseSpring
                </PositionTitle>
                <div>
                  <a
                    href="https://www.linkedin.com/in/edmonmoren/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <ImageIcon src={LinkedIn} />
                  </a>

                  <a href="mailto: edmon@licensespring.com">
                    <ImageIcon src={Email} />
                  </a>
                </div>
              </div>
            </CeoHolder>
          </div>
        </QuoteHolder>
      </Container>
    </Wrapper>
  )
}
