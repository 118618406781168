import React from "react"
import { Wrapper, Container, Title } from "../shared.styles"
import Border from "../../../assets/img/TimelineBorder-bulletpoint.svg"

import {
  ContentHolder,
  Paragraph,
  SubTitle,
  Year,
  StoryHolder,
  TimelineYear,
  TimeLineHolder,
  Line,
  LineHolder,
  LineIcon,
  PDFLink,
} from "./story.styled"

export const Story = () => {
  return (
    <Wrapper mt={"100px"}>
      <Container>
        <Title>Our Story</Title>
        <Paragraph margin="60px auto 50px auto" align="center" maxw="950px">
          Our platform changelog provides a detailed picture of how the
          LicenseSpring service grew and evolved over time. Our development
          roadmap has always been focused on delivering what our customers
          requested.
        </Paragraph>
        <ContentHolder>
          <StoryHolder>
            <TimelineYear>2015</TimelineYear>
            <LineHolder>
              <LineIcon src={Border} />
              <Line />
            </LineHolder>
            <TimeLineHolder>
              <Year>2015</Year>
              <SubTitle>The Start</SubTitle>
              <Paragraph maxw="800px">
                Our sister company,{" "}
                <PDFLink href="https://pdfpro.com/" target="_blank">
                  PDF Pro Software Inc
                </PDFLink>{" "}
                was in need of a License Manager to provision, manage, and
                automate thousands of software licenses issued daily. After
                performing a thorough analysis of as many vendors we could find,
                we concluded that the smaller players lacked the sophistication
                necessary to meet our specific requirements, while the larger
                vendors proved cumbersome to use, while their pricing felt
                unjustifiably high. As a result, we sought a more suitable
                solution that struck the right balance between sophistication,
                usability, and fair pricing. We decided to build our own license
                server for our own needs.
              </Paragraph>
            </TimeLineHolder>
          </StoryHolder>
          <StoryHolder>
            <TimelineYear>2017</TimelineYear>
            <LineHolder>
              <LineIcon src={Border} />
              <Line />
            </LineHolder>
            <TimeLineHolder>
              <Year>2017</Year>
              <SubTitle>Initial Public Release</SubTitle>
              <Paragraph maxw="800px">
                mplementing our license server within the{" "}
                <PDFLink href="https://pdfpro.com/" target="_blank">
                  PDF Pro
                </PDFLink>{" "}
                Application was a solid success, and made the service available
                to the world for free in order to collect feedback, and feedback
                there was! hundreds of solo developers and hobbyists jumped in
                and provided mountains of valuable feedback that we used to
                improve LicenseSpring.
              </Paragraph>
            </TimeLineHolder>
          </StoryHolder>
          <StoryHolder>
            <TimelineYear>2019</TimelineYear>
            <LineHolder>
              <LineIcon src={Border} />
              <Line />
            </LineHolder>
            <TimeLineHolder>
              <Year>2019</Year>
              <SubTitle>Open for Business</SubTitle>
              <Paragraph maxw="800px">
                The first externally paying customers started to flow in, and
                were primarily using LicenseSpring as a means of issuing time
                limited and node locked Borderlicenses for desktop applications
                that handled a high volume of licenses, with offline license
                checks. SDKs targetting C++, C#, and Java systems were made
                available to make it as easy as possible to add Licensing within
                our customer’s applications.
              </Paragraph>
            </TimeLineHolder>
          </StoryHolder>

          <StoryHolder>
            <TimelineYear>2021</TimelineYear>
            <LineHolder>
              <LineIcon src={Border} />
              <Line />
            </LineHolder>
            <TimeLineHolder>
              <Year>2021</Year>
              <SubTitle>Serving more Customers</SubTitle>
              <Paragraph maxw="800px">
                We extended our service’s capabilities to cater to a diverse
                range of customer requirements across various industries. We
                released Feature-based licensing, consumption-based licensing,
                custom fields, floating licensing, and single-sign-on support.
                Our clientele now spans across Life Sciences, Critical
                Infrastructure, Aerospace, VR/AR, Manufacturing, and more,
                enabling us to serve a broader spectrum of needs and industries.
              </Paragraph>
            </TimeLineHolder>
          </StoryHolder>

          <StoryHolder>
            <TimelineYear>2023</TimelineYear>
            <LineHolder>
              <LineIcon src={Border} />
              <Line />
            </LineHolder>
            <TimeLineHolder>
              <Year>2023</Year>
              <SubTitle>Present and Beyond</SubTitle>
              <Paragraph maxw="800px">
                We are currently hard at work maintaining and improving the
                LicenseSpring service, with an ever growing engineering team.
                This includes adding more functionality to our API, extending
                our portals, adding more native integrations, always emphasizing
                our security and compliance.
              </Paragraph>
            </TimeLineHolder>
          </StoryHolder>
        </ContentHolder>
      </Container>
    </Wrapper>
  )
}
