import styled from "styled-components"

export const BorderTop = styled.div`
  padding-top: 130px;
  padding-bottom: 100px;

  @media (max-width: 920px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
`
export const AspectHolder = styled.div`
  margin-top: 60px;
  display: flex;
  gap: 10%;

  @media (max-width: 920px) {
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
  }
`
export const AspectItem = styled.div`
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f0f9fe;
  border-radius: 32px;
  width: max-content;
  padding: 64px 20%;
  width: 100%;

  @media (max-width: 920px) {
    padding: 10% 20%;
    width: 60%;
  }
`
export const AspectImage = styled.img`
  max-width: 100%;
  height: auto;

  @media (max-width: 920px) {
    height: 100%;
    width: 100%;
  }
`
export const NumberHolder = styled.div`
  position: absolute;
  left: 30px;
  bottom: -40px;
  font-size: 78px;
  font-family: "DM Sans";

  @media (max-width: 920px) {
    left: 30px;
    bottom: -15px;
    font-size: 38px;
  }
  @media (max-width: 420px) {
    left: 0px;
    bottom: -15px;
    font-size: 38px;
  }
`
export const ContentAspect = styled.div`
  margin-top: 40px;
`
